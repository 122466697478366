import React from 'react';

const PrivacyPolicy = () => {
  const [pageContent, setPageContent] = React.useState('');
  const [didError, setDidError] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://www.iubenda.com/api/privacy-policy/45184077/no-markup'
        );
        const json = await response.json();
        const content = json.content.replace(
          '<h1>Privacy Policy of <strong>www.brandcloudlines.com</strong></h1>',
          ''
        );
        setDidError(false);
        setPageContent(content);
      } catch (error) {
        console.error(error);
        setDidError(true);
      }
    };

    fetchData();
  }, []);

  return didError ? (
    <div>
      View our{' '}
      <a href="https://www.iubenda.com/privacy-policy/45184077/legal">
        Privacy Policy
      </a>
      .
    </div>
  ) : (
    <div
      dangerouslySetInnerHTML={{
        __html: pageContent,
      }}
    />
  );
};

export default PrivacyPolicy;
