// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-legal-template-js": () => import("/Users/steveburtenshaw/Documents/Business/SomeOne/Cloudlines/Landing page/cloudlines-landing-page/src/templates/legalTemplate.js" /* webpackChunkName: "component---src-templates-legal-template-js" */),
  "component---src-pages-index-js": () => import("/Users/steveburtenshaw/Documents/Business/SomeOne/Cloudlines/Landing page/cloudlines-landing-page/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("/Users/steveburtenshaw/Documents/Business/SomeOne/Cloudlines/Landing page/cloudlines-landing-page/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/steveburtenshaw/Documents/Business/SomeOne/Cloudlines/Landing page/cloudlines-landing-page/.cache/data.json")

